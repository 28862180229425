import React, { ReactNode } from 'react';
import { Waypoint } from 'react-waypoint';
import PageSection, { PageSectionBackground } from '../ui/PageSection';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';

interface FeaturesChapterProps<T extends string> {
  id: T;
  background?: PageSectionBackground;
  children: ReactNode;
  onEnter: (anchor: T) => void;
  onLeave: (anchor: T) => void;
}

const FeaturesChapter = <T extends string>({
  id,
  background,
  children,
  onEnter,
  onLeave,
}: FeaturesChapterProps<T>) => (
  <PageSection background={background} id={id}>
    <Spacing vertical={10}>
      <Waypoint
        onEnter={() => {
          onEnter(id);
        }}
        onLeave={() => {
          onLeave(id);
        }}
      >
        <div>
          <Text darker>{children}</Text>
        </div>
      </Waypoint>
    </Spacing>
  </PageSection>
);

export default FeaturesChapter;
