import React from 'react';
import Spacing from '../ui/Spacing';

interface FeatureSection {
  title: string;
  children: React.ReactNodeArray;
}

const FeaturesSection: React.SFC<FeatureSection> = ({ title, children }) => (
  <div>
    <Spacing bottom={7}>
      <h4>{title}</h4>
    </Spacing>
    {React.Children.map(children, (child) => (
      <Spacing bottom={5}>{child}</Spacing>
    ))}
  </div>
);

export default FeaturesSection;
