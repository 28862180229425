import React from 'react';
import { Waypoint } from 'react-waypoint';
import collaborateIcon from '../../images/feature-icons/collaborate-icon.svg';
import organizationIcon from '../../images/feature-icons/organize-icon.svg';
import retrieveIcon from '../../images/feature-icons/retrieve-icon.svg';
import collaborate1 from '../../images/features/collaborate-1.svg';
import collaborate2 from '../../images/features/collaborate-2.svg';
import organization1 from '../../images/features/organize-1.svg';
import organization2 from '../../images/features/organize-2.svg';
import retrieve2 from '../../images/features/retrieve-2.svg';
import retrieve1 from '../../images/home/features/retrieve.svg';
import TestimonyBlockquote from '../testimonies/TestimonyBlockquote';
import PageSection from '../ui/PageSection';
import Row from '../ui/Row';
import SimpleSection from '../ui/SimpleSection';
import Spacing from '../ui/Spacing';
import FeaturesChapter from './FeaturesChapter';
import FeaturesChapterTitle from './FeaturesChapterTitle';
import FeaturesIntegrationsInsert from './FeaturesIntegrationsInsert';
import FeaturesSection from './FeaturesSection';
import FeaturesSubNavBar from './FeaturesSubNavBar';

type ChapterAnchors = 'organize' | 'collaborate' | 'retrieve';

interface FeaturesChaptersState {
  activeAnchors: ChapterAnchors[];
  showSubNavbar: boolean;
}

class FeaturesChapters extends React.Component<unknown, FeaturesChaptersState> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state: FeaturesChaptersState = {
    activeAnchors: [],
    showSubNavbar: false,
  };

  handleWaypointPositionChange = ({
    currentPosition,
  }: {
    currentPosition?: string;
  }) => {
    this.setState({ showSubNavbar: currentPosition === Waypoint.above });
  };

  handleChapterEnter = (anchor: ChapterAnchors) => {
    this.setState({ activeAnchors: [...this.state.activeAnchors, anchor] });
  };

  handleChapterLeave = (anchor: ChapterAnchors) => {
    this.setState({
      activeAnchors: this.state.activeAnchors.filter((a) => a !== anchor),
    });
  };

  render() {
    return (
      <div>
        <Waypoint
          onPositionChange={this.handleWaypointPositionChange}
          topOffset="20px"
        />
        <FeaturesSubNavBar
          activeAnchor={this.state.activeAnchors[0]}
          visible={this.state.showSubNavbar}
        />
        <FeaturesChapter
          id="organize"
          onEnter={this.handleChapterEnter}
          onLeave={this.handleChapterLeave}
        >
          <Row reversed even>
            <div>
              <img src={organization1} alt="Organization illustration 1" />
            </div>
            <div>
              <Spacing bottom={7}>
                <FeaturesChapterTitle icon={organizationIcon}>
                  Organize
                </FeaturesChapterTitle>
              </Spacing>
              <FeaturesSection title="Find what you're looking for.">
                <SimpleSection title="Organize your wiki to fit your workflow">
                  Slab organizes content by hierarchical topics you can easily
                  structure and everyone can view. You can also apply many
                  topics to one post (like labels). Choose the combination that
                  best fits your information architecture.
                </SimpleSection>
                <SimpleSection title="More than just a list of files">
                  Slab Topics show the necessary context behind the content. See
                  what&apos;s important to read, in what order, and who to ask
                  for more info if you get stuck.
                </SimpleSection>
              </FeaturesSection>
            </div>
          </Row>
          <Spacing vertical={10}>
            <TestimonyBlockquote slug="rishi" />
          </Spacing>
          <Row even>
            <div>
              <img src={organization2} alt="Organization illustration 2" />
            </div>
            <FeaturesSection title="Keep your knowledge base relevant and useful.">
              <SimpleSection title="Admin insights">
                See actionable insights into which posts have been most helpful
                to your team and which are getting stale. Learn the questions
                your team is asking that go unanswered. Use this data to keep
                your knowledge from growing stale.
              </SimpleSection>
              <SimpleSection title="Get feedback on your posts">
                Teammates can get and give feedback on content, including
                commenting throughout the post, thanking authors for their
                contribution, and using emoji reactions to express support or
                confusion. This real-time feedback helps authors know which
                parts of their content are helpful or potentially confusing.
              </SimpleSection>
            </FeaturesSection>
          </Row>
        </FeaturesChapter>
        <FeaturesChapter
          id="collaborate"
          background="light"
          onEnter={this.handleChapterEnter}
          onLeave={this.handleChapterLeave}
        >
          <Row reversed even>
            <div>
              <img src={collaborate1} alt="Collaborate illustration 1" />
            </div>
            <div>
              <Spacing bottom={7}>
                <FeaturesChapterTitle icon={collaborateIcon}>
                  Collaborate
                </FeaturesChapterTitle>
              </Spacing>
              <FeaturesSection title="Stay synced with your team.">
                <SimpleSection title="Good-looking content, without trying">
                  Fast and intuitive content creation that looks good by
                  default. Focus on growing your team&apos;s core competencies,
                  not fighting over font sizes.
                </SimpleSection>
                <SimpleSection title="Real-time collaboration">
                  Slab&apos;s collaborative editor ensures everyone is working
                  from the latest version—and no one will overwrite anyone
                  else&apos;s work. You can even edit the same post with
                  teammates at the same time.
                </SimpleSection>
                <SimpleSection title="Richer than text">
                  Capture more than just text on a page. Add rich media,
                  interactive widgets, and third-party embeds that make each
                  post easier to understand.
                </SimpleSection>
              </FeaturesSection>
            </div>
          </Row>
          <Spacing vertical={10}>
            <TestimonyBlockquote slug="rishi" />
          </Spacing>
          <Row even>
            <div>
              <img src={collaborate2} alt="Collaborate illustration 2" />
            </div>
            <FeaturesSection title="Integrate with your favorite tools.">
              <SimpleSection title="GitHub">
                Live markdown file sync lets you keep your code review on Github
                and see the content on Slab. Preview GitHub Issues in-line
                within Slab.
              </SimpleSection>
              <SimpleSection title="Slack">
                Start an idea in Slack, flesh out the details in Slab, and
                publish right into Slack when you&apos;re ready to share with
                the whole team.
              </SimpleSection>
              <SimpleSection title="Google Workspace">
                Use Google Single Sign-On to simplify account permissions and
                manage your team roster. Add Google Drive sync to search and
                embed Docs, Sheets, and Slides inside Slab.
              </SimpleSection>
            </FeaturesSection>
          </Row>
        </FeaturesChapter>
        <PageSection background="light-half">
          <FeaturesIntegrationsInsert />
        </PageSection>
        <FeaturesChapter
          id="retrieve"
          onEnter={this.handleChapterEnter}
          onLeave={this.handleChapterLeave}
        >
          <Row even reversed>
            <div>
              <img src={retrieve1} alt="Retrieve illustration 1" />
            </div>
            <div>
              <Spacing bottom={7}>
                <FeaturesChapterTitle icon={retrieveIcon}>
                  Retrieve
                </FeaturesChapterTitle>
              </Spacing>
              <FeaturesSection title="Get answers immediately, no matter where shared knowledge is stored.">
                <SimpleSection title="Blazing fast search">
                  Get answers faster with Slab&apos;s search, optimized for
                  speed. Results appear before you finish your thought as you
                  type.
                </SimpleSection>
                <SimpleSection title="Unified search across integrations">
                  To access all your content in one place, integrate your other
                  tools and add them to Slab&apos;s search index.
                </SimpleSection>
                <SimpleSection title="Relevance and importance">
                  Slab tracks reads, views, links, and mentions to show you
                  results that are based on relevance and importance — so you
                  get the most definitive answers to your questions.
                </SimpleSection>
              </FeaturesSection>
            </div>
          </Row>
          <Spacing vertical={10}>
            <TestimonyBlockquote slug="rishi" />
          </Spacing>
          <Row even>
            <div>
              <img src={retrieve2} alt="Retrieve illustration 2" />
            </div>
            <FeaturesSection title="Set your team up for success.">
              <SimpleSection title="Favorites">
                Use Favorites to add shortcuts to common posts and topics.
                Easily access them right from the sidebar and homepage to
                retrieve your most useful work faster.
              </SimpleSection>
              <SimpleSection title="Profiles">
                With Slab Provisions, admins can improve onboarding through
                custom user templates that show the right content to the right
                people. Salespeople, for instance, can have their sales playbook
                front and center when they sign into Slab, while engineers see
                their production deployment guide.
              </SimpleSection>
            </FeaturesSection>
          </Row>
        </FeaturesChapter>
      </div>
    );
  }
}

export default FeaturesChapters;
