import React from 'react';
import AriaModal from 'react-aria-modal';
import heroImage from '../../images/features/hero.svg';
import playButtonImage from '../../images/features/play.svg';
import Button from '../ui/Button';
import PageSection from '../ui/PageSection';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './FeaturesHeader.module.scss';

interface FeaturesHeaderState {
  isShowingVideoModal: boolean;
}

class FeaturesHeader extends React.Component<unknown, FeaturesHeaderState> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state: FeaturesHeaderState = {
    isShowingVideoModal: false,
  };

  handleVideoToggle = () => {
    const { isShowingVideoModal } = this.state;
    this.setState({ isShowingVideoModal: !isShowingVideoModal });
  };

  render() {
    return (
      <div className={styles.header}>
        <PageSection>
          <Spacing vertical={4}>
            <h2>The team wiki that knocks down silos.</h2>
          </Spacing>
          <Spacing bottom={4}>
            <Text medium>
              <div className={styles.description}>
                An intuitive editor. Blazing fast search capabilities. Dozens of
                integrations. Slab is the one place for your team&apos;s shared
                knowledge.
              </div>
            </Text>
          </Spacing>
        </PageSection>
        <div className={styles.heroContainer}>
          <Button unstyled onClick={this.handleVideoToggle}>
            <div className={styles.heroButton}>
              {/*  eslint-disable-next-line jsx-a11y/img-redundant-alt */}
              <img
                className={styles.heroImage}
                src={heroImage}
                alt="Features Hero Image"
              />
              <img
                className={styles.playButton}
                alt="Play"
                src={playButtonImage}
              />
            </div>
          </Button>
        </div>
        {this.state.isShowingVideoModal && (
          <AriaModal
            dialogClass={styles.videoDialog}
            titleText="Slab Video"
            onExit={this.handleVideoToggle}
            verticallyCenter
          >
            <div className={styles.videoModalClose}>
              <Button onClick={this.handleVideoToggle} unstyled>
                ✕
              </Button>
            </div>
            <div className={styles.videoIframeContainer}>
              {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
              <iframe
                className={styles.videoIframe}
                src="https://player.vimeo.com/video/288780321?autoplay=1&color=ffffff&title=0&byline=0&portrait=0"
                frameBorder="0"
                sandbox="allow-scripts allow-same-origin"
                allowFullScreen
              />
            </div>
          </AriaModal>
        )}
      </div>
    );
  }
}

export default FeaturesHeader;
