import React from 'react';
import IconHeader from '../ui/IconHeader';
import styles from './FeaturesChapterTitle.module.scss';

interface FeaturesChapterTitleProps {
  icon: string;
  children: string;
}

const FeaturesChapterTitle: React.SFC<FeaturesChapterTitleProps> = ({
  icon,
  children,
}) => (
  <div className={styles.container}>
    <IconHeader icon={icon}>
      <h2>{children}</h2>
    </IconHeader>
  </div>
);

export default FeaturesChapterTitle;
