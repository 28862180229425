import classNames from 'classnames';
import React from 'react';
import TestimonyQuery from '../../utils/TestimonyQuery';
import Text from '../ui/Text';
import styles from './TestimonyBlockquote.module.scss';

interface TestimonyBlockquoteProps {
  slug: string;
  small?: boolean;
  companyAvatar?: boolean;
  theme?: 'untitled' | undefined;
}

const TestimonyBlockquote: React.SFC<TestimonyBlockquoteProps> = ({
  slug,
  small,
  companyAvatar,
  theme,
}) => (
  <TestimonyQuery
    slug={slug}
    render={({
      company: { url: companyUrl, name: companyName },
      name,
      picture,
      quote,
      title,
    }) => (
      <blockquote
        className={classNames(!small ? styles.blockquote : styles.small, {
          [styles.blockquoteMedium]: quote.length > 140,
          [styles.blockquoteSmall]: quote.length > 200,
        })}
      >
        {small ? (
          <div className={styles.smallTestimonyBlockquote}>
            <span className={styles.smallTestimonyQuoteMark}>
              <svg
                width="14"
                height="9.25"
                viewBox="0 0 32 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.276 20.36C1.77667 20.36 1.34867 20.1817 0.992 19.825C0.706667 19.4683 0.564 19.0403 0.564 18.541C0.564 18.113 0.671 17.5423 0.885 16.829L6.449 2.491C6.80567 1.63499 7.198 1.02866 7.626 0.671994C8.054 0.243995 8.696 0.0299959 9.552 0.0299959H12.869C13.4397 0.0299959 13.8677 0.243995 14.153 0.671994C14.5097 1.1 14.6167 1.635 14.474 2.277L12.12 17.257C11.906 19.3257 10.9073 20.36 9.124 20.36H2.276ZM19.075 20.36C18.5757 20.36 18.1477 20.1817 17.791 19.825C17.5057 19.4683 17.363 19.0403 17.363 18.541C17.363 18.113 17.47 17.5423 17.684 16.829L23.248 2.491C23.6047 1.63499 23.997 1.02866 24.425 0.671994C24.853 0.243995 25.495 0.0299959 26.351 0.0299959H29.668C30.2387 0.0299959 30.6667 0.243995 30.952 0.671994C31.3087 1.1 31.4157 1.635 31.273 2.277L28.919 17.257C28.705 19.3257 27.7063 20.36 25.923 20.36H19.075Z"
                  fill="#DAD2D2"
                />
              </svg>
            </span>
            <p
              className={classNames(styles.smallTestimonyQuoteText, {
                [styles.untitled]: theme === 'untitled',
              })}
            >
              {quote}
            </p>
          </div>
        ) : (
          <p
            className={classNames(styles.quoteText, {
              [styles.untitled]: theme === 'untitled',
            })}
          >
            &ldquo;
            {quote}
            &rdquo;
          </p>
        )}

        <footer>
          <cite className={small ? styles.smallTestimonyCite : null}>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a
              href={companyUrl}
              className={styles.by}
              target="_blank"
              rel="noopener"
            >
              {picture.publicURL && (
                <img
                  alt=""
                  role="presentation"
                  className={`${styles.avatar} ${
                    companyAvatar ? styles.companyAvatar : ''
                  }`}
                  loading="lazy"
                  src={picture.publicURL}
                />
              )}
              <div>
                {small ? (
                  <>
                    <Text darker>{name}</Text>
                    <Text muted>
                      {title}, {companyName}
                    </Text>
                  </>
                ) : (
                  <>
                    <p className={styles.quoteAuthor}>{name}</p>
                    <p className={styles.quoteAuthorTitle}>
                      {title}, {companyName}
                    </p>
                  </>
                )}
              </div>
            </a>
          </cite>
        </footer>
      </blockquote>
    )}
  />
);

export default TestimonyBlockquote;
