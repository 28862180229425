import classNames from 'classnames';
import React from 'react';
import RegisterForm from '../account/RegisterForm';
import PageSection from '../ui/PageSection';
import styles from './FeaturesSubNavBar.module.scss';

interface FeaturesSubNavBarItem {
  name: string;
  anchor: string;
}

interface FeaturesSubNavBarProps {
  activeAnchor?: string;
  visible: boolean;
}

const ITEMS: FeaturesSubNavBarItem[] = [
  { name: 'Organize', anchor: 'organize' },
  { name: 'Collaborate', anchor: 'collaborate' },
  { name: 'Retrieve', anchor: 'retrieve' },
];

const FeaturesSubNavBar: React.SFC<FeaturesSubNavBarProps> = ({
  activeAnchor = ITEMS[0].anchor,
  visible,
}) => (
  <div
    className={classNames(styles.container, {
      [styles.visible]: visible,
    })}
  >
    <PageSection>
      <div className={styles.items}>
        {ITEMS.map(({ name, anchor }) => (
          <a
            key={anchor}
            href={`#${anchor}`}
            className={classNames(styles.item, styles[anchor], {
              [styles.active]: activeAnchor === anchor,
            })}
          >
            {name}
          </a>
        ))}
        <div className={styles.registerForm}>
          <RegisterForm compact source="features" />
        </div>
      </div>
    </PageSection>
  </div>
);

export default FeaturesSubNavBar;
