import React from 'react';
import FeaturesChapters from '../components/features/FeaturesChapters';
import FeaturesHeader from '../components/features/FeaturesHeader';
import MarketingLayout from '../components/layout/MarketingLayout';

const IndexPage = () => (
  <MarketingLayout navbarBackground="plum" noStickyNavbar title="Features">
    <FeaturesHeader />
    <FeaturesChapters />
  </MarketingLayout>
);

export default IndexPage;
